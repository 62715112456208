<template lang="pug">
footer.footer
  p Developed &nbsp;
    a(href="https://yegupov.github.io/en/" target="_blank") Gennady Yegupov
    a(href="https://github.com/yegupov/vuejs-landing-and-admin" target="_blank" title="LANDING & ADMIN") GitHub repo
</template>

<script>
export default {
  name: 'FooterBar'
};
</script>
